<template>
  <dl class="terms">
    <dt>{{$t('content.event.Legal.Reservation.txt01')}}</dt>
    <dd>
      {{$t('content.event.Legal.Reservation.txt02')}}

    </dd>
    <dt>{{$t('content.event.Legal.Reservation.txt03')}}</dt>
    <dd v-html="$t('content.event.Legal.Reservation.txt04')">

    </dd>
    <dt>{{$t('content.event.Legal.Reservation.txt05')}}</dt>
    <dd v-html="$t('content.event.Legal.Reservation.txt06')">

    </dd>
    <dt>{{$t('content.event.Legal.Reservation.txt07')}}</dt>
    <dd v-html="$t('content.event.Legal.Reservation.txt08')">

    </dd>
    <dt>{{$t('content.event.Legal.Reservation.txt09')}}</dt>
    <dd v-html="$t('content.event.Legal.Reservation.txt10')">

    </dd>
    <dt>{{$t('content.event.Legal.Reservation.txt11')}}</dt>
    <dd v-html="$t('content.event.Legal.Reservation.txt12')">

    </dd>
    <dt>{{$t('content.event.Legal.Reservation.txt13')}}</dt>
    <dd v-html="$t('content.event.Legal.Reservation.txt14')">

    </dd>
    <dt>{{$t('content.event.Legal.Reservation.txt15')}}</dt>
    <dd v-html="$t('content.event.Legal.Reservation.txt16')">

    </dd>
    <dt>{{$t('content.event.Legal.Reservation.txt17')}}</dt>
    <dd>
      {{$t('content.event.Legal.Reservation.txt18')}}

    </dd>
  </dl>
</template>

<script>
/**
 * 대관신청 약관
 */
export default {
  name: 'ReservationLegal',
  methods: {},
};
</script>

<style>
</style>
